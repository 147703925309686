
@font-face {
  font-family: Sarabun;
  src: url('../font/Sarabun-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: Sarabun;
  src: url('../font/Sarabun-Bold.ttf');
  font-weight: bold;
}

body {
  font-family: 'Sarabun';
  background-color: #f7f7f7;
  font-size: 16px;
}

.loading-view {
  top: 0px !important;
  height: 100vh;
  margin: 0px;
  max-width: 100%;
  z-index: 20;
}

.editor-view {
  top: 0px !important;
  height: 100vh;
  margin: 0px;
  max-width: 100%;
  z-index: 10;
}

.loading-view, .editor-view > .ant-modal-content {
  background-color: transparent;
  box-shadow: none;
}

.loading-view, .editor-view > .ant-modal-content > .ant-modal-body {
  padding: 0px;
}

.ant-layout {
  background: #EEEEEE;
}

.my-drawer > .ant-drawer-content-wrapper
> .ant-drawer-content > .ant-drawer-wrapper-body > .ant-drawer-header {
  padding: 0px;
}


.App {
  text-align: center;
  width: 95%;
  margin: 15px auto;
}

.container-drag {
  text-align: center;
}

.wip {
  position: absolute;
  width: 150px;
  height: 100vh;
  left: 0;
  top: 10;
  background-color: #EEEEEE;
  border-right: 1px dotted;
}

.header {
  display: inline-block;
  margin: 0;
  padding: 0;
  background-color: #476268;
  width:100%;
}

.task-header {
  display: inline-block;
  background-color: skyblue;
  width:100%;
}

.droppable {
  position: absolute;
  width: 150px;
  height: 100vh;
  right: 0;
  top: 10;
  background-color: #9E9E9E;
  border-left: 1px dotted;
}

.draggable {
  width: 100px;
  height: 100px;
  background-color: yellow;
  margin: 5px auto;
  line-height: 5em;
}

@page {
  size: portrait;
  width: 210mm;
  height: 297mm;
  margin: 0px;
  margin-bottom: 10mm;

  background-color: white;
}

#widthie {
  display: none;
}

.user-menu {
  width: 180px;
}

@media screen and (max-width: 450px) {
  .user-menu {
    width: 250px;
  }
}
