.ant-popover-inner-content {
  padding: 0px;
}

.order-view {
  top: 0px !important;
  height: 100vh;
  margin: 0px;
  max-width: 100%;
  z-index: 10;
}

.order-view > .ant-modal-content {
  background-color: transparent;
  box-shadow: none;
}

.order-view > .ant-modal-content > .ant-modal-body {
  padding: 0px;
}
